import React from 'react';
import Slider from 'react-slick';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonialFive from '../../components/Testimonial/SingleTestimonialFive';

import quote from '../../assets/img/testimonial/home12/quote.png';
import author1 from '../../assets/img/testimonial/style5/1.png';
import author2 from '../../assets/img/testimonial/style5/2.png';
import author3 from '../../assets/img/testimonial/style5/3.png';

const Testimonial = () => {
  const testimonialSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="rs-testimonial home12-style">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title4 mb-50 md-mb-30 text-center"
            subtitleClass="sub-title primary"
            subtitle="Testimonial"
            titleClass="title mb-0"
            title="What Client Saying"
          />
          <Slider {...testimonialSettings}>
            <SingleTestimonialFive
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={author1}
              Title="David "
              Designation="CEO"
              Description="Since using Hi PROS's PRO Work app, my team's efficiency has skyrocketed. We can now manage projects seamlessly, collaborate remotely with ease, and track progress in real-time. This has freed up valuable time for us to focus on more strategic tasks"
            />
            <SingleTestimonialFive quoteImage={quote} authorImage={author2} />
            <SingleTestimonialFive
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={author3}
              Title="Rahul"
              Designation="Freelancer"
              Description="As a freelancer, clear communication with clients is essential. HI PRO's PRO Work platform has been a game-changer. It allows me to share files securely, keep clients updated on project progress, and receive feedback efficiently. My clients are impressed with the level of professionalism and organization it facilitates."
            />
            <SingleTestimonialFive
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={author2}
              Title="Salman"
              Designation="Engineer"
              Description="Juggling multiple projects can be overwhelming. However, HI PRO's PRO Work software has helped me stay organized and on top of my deadlines. With automated workflows and the ability to prioritize tasks, I feel less stressed and more in control of my workload. It's a lifesaver for busy professionals like myself."
            />
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
