import React, { useEffect, useState } from 'react';
import clientService from '../../../pages/service/clientService';
import { Row, Col } from 'react-bootstrap';

import NoImage from '../../../assets/img/no-image.png';
import { Link } from 'react-router-dom';

export default function Certifcate({comid}) {
  const [attestList, setAttestList] = useState([]);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const userId = userData.id;

  useEffect(() => {
    getAllcertiAttestation();
  }, []);

  const getAllcertiAttestation = async () => {
    try {
      const data = await clientService.getAllcertificateAttestation(comid);
      setAttestList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) { }
  };

  const deleteCertificateAttest=async(id)=>{
    console.log(id)
    console.log(`deleted id ${id}`)
    const res= await clientService.deleteAttest(id)
    getAllcertiAttestation()
    
}

  return (
    <div className="blog-deatails">
      {attestList?.length === 0 ? (
        <>
          <span style={{ alignItems: 'center' }}>
            <img src={NoImage} style={{ width: '25%' }} />
          </span>
        </>
      ) : (
        attestList?.map((item) => (
          <div className="blog-full">
            <blockquote>
              <p>{item?.noOfMembers} Members</p>
              <p>Nationality : {item?.nationality?.name} </p>
              <p>Certification Type:{item?.certificateType?.name}</p>
              <p>Attestation Type:{item?.attestType?.name}</p>
              <p>Collection Address{item?.collectionAddress}</p>
              <Row>
                <Col>

                  <p><img width='100px' src={item?.upload1} alt='uploaded doc1' /> <br />{item?.uploadName1}</p>

                </Col>
                <Col>
                  <p><img width='100px' src={item?.upload2} alt='uploaded doc1' /> <br />{item?.uploadName2}</p>
                </Col>
                <Col>
                  <p><img width='100px' src={item?.upload3} alt='uploaded doc1' /> <br />{item?.uploadName3}</p>

                </Col>
              </Row>


              <ul className="single-post-meta">
                <li>
                  <span className="p-date">
                    {' '}
                    <i className="fa fa-calendar-check-o"></i> April 6, 2022{' '}
                  </span>
                </li>

                <li className="post-comment">
                  {' '}
                  <i className="fa fa-comments-o"></i> Requested
                </li>
              </ul>

              <div className="d-flex flex-row">
                <div className="p-2">
                  {/* /editschengen/R7zTHyDbr1YArG8Kplxb */}
                  <Link to={`/editfamilyvisa/${item.id}`}>
                    <button type="button" className="btn btn-outline-dark btn-sm">
                      Edit
                    </button>
                  </Link>

                </div>
                <div className="p-2">
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => {
                      if (window.confirm('Do you want to delete ?')) {
                        deleteCertificateAttest(item.id)
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>

            </blockquote>
          </div>
        ))
      )}
    </div>
  );
}
