import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
  Redirect
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import bgImg from '../assets/img/bg.png';

//Custom Components

import MainDashboard from '../pages/sky-services/MainDashboard';
import RemainderView from '../pages/sky-services/view/Reminders';
import DocRemainder from '../pages/sky-services/doc-reminder/reminder';
import EditReminder from '../pages/sky-services/doc-reminder/EditReminder';

import FamilyView from '../pages/sky-services/view/FamilyVisa';
import FamilyVisaRequest from '../pages/sky-services/family-visa/family-request';
import EditFamily from '../pages/sky-services/family-visa/EditFamily';

import CertificateView from '../pages/sky-services/view/Certifcate';
import CertificateAttestation from '../pages/sky-services/certificate/certificate';
import EditCertificate from '../pages/sky-services/certificate/EditCertificate';

import SchengenView from '../pages/sky-services/view/Shengen';
import SchegenRequest from '../pages/sky-services/schengen-visa/schegenRequest';
import EditSchengenRequest from '../pages/sky-services/schengen-visa/EditSchengenRequest';



import Login from '../pages/sky-services/signup/login';
import Register from '../pages/sky-services/signup/register';

import MyAccount from '../pages/shop/my-account';
import HomeTwelve from '../pages/home-12';

import LoadTop from '../components/Common/ScrollTop/LoadTop';

import Header from '../pages/sky-services/Header';
import Footer from '../pages/sky-services/Footer';
import Contact from '../pages/sky-services/Contact';

// 

// 
//

// 

// import Checkout from '../pages/shop/checkout';
// 
// 
// 
// 
// 
// import Signup from '../pages/sky-services/signup/Signup'
// import ViewAttest from '../pages/sky-services/certificate/ViewAttest';
// import ViewDocRemainder from '../pages/sky-services/doc-reminder/ViewDocRemainder';
// import Additionalinfo from '../pages/sky-services/doc-reminder/Additionalinfo';
// import ViewFamilyVisa from '../pages/sky-services/family-visa/ViewFamilyVisa';
// import ViewFamilyVisaDetail from '../pages/sky-services/family-visa/ViewFamilyVisaDetail';
// 
// import ViewSchen from '../pages/sky-services/schengen-visa/ViewSchen';
// import ViewComplete from '../pages/sky-services/signup/ViewComplete'
// import View from '../pages/sky-services/view/View';
import ViewCompanyUser from'../pages/sky-services/viewcompanyuser/View'
// 
// import ProfileView from '../pages/sky-services/signup/ProfileView';
// 
//
// 

const bgStyle = {
  outerHeight: '100vh',
  marginTop: '60px',
};

const App = () => {
  // const isAuthenticated=()=>{
  //   return localStorage.getItem('userData')!==null
  // }

  return (
    <div className="App" style={bgStyle}>
      <Header />

      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={MainDashboard} />
          <Route path="/dashremview" component={RemainderView} />
          <Route path="/docremainder/request" component={DocRemainder} />
          <Route path="/doc/update/:id" component={EditReminder} />
          <Route path="/dashfamilyview" component={FamilyView} />
          <Route path="/visa/family/request" component={FamilyVisaRequest} />
          <Route path="/editfamilyvisa/:id" component={EditFamily} />
          <Route path="/dashcertiview" component={CertificateView} />
          <Route
            path="/certificate/request"
            component={CertificateAttestation}
          />
          <Route path="/editcertificate/:id" component={EditCertificate} />
          <Route path="/dashschengenview" component={SchengenView} />
          <Route path="/schengen/request" component={SchegenRequest} />
          <Route path="/editschengen/:id" component={EditSchengenRequest} />
          <Route path="/my-account" component={MyAccount} />
          <Route path="/about" component={HomeTwelve} />;
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/contact" component={Contact} />
          <Route path="/viewcompanyuser/:id" component={ViewCompanyUser} />

          {/*  */}
          {/* <Route path="/" exact component={HomeTwelve} /> */}
          {/* */}
          {/* <Route path="/login" component={Login} />
         
         
          <Route path="/doc/update/:id" component={EditReminder} />

          <Route path="/signup" component={Signup} />
          <Route path="/viewattest" component={ViewAttest} />
          <Route path="/viewdoc" component={ViewDocRemainder} />
          <Route path="/additionaldoc" component={Additionalinfo} />
          <Route path="/viewschen" component={ViewSchen} />
          <Route path="/viewfamily" component={ViewFamilyVisa} />
          
          <Route path="/familydetails" component={ViewFamilyVisaDetail} />
          <Route path="/view" component={View} />
          <Route path="/viewcompanyuser/:id" component={ViewCompanyUser} />
          <Route path="/completeview" component={ViewComplete} />
          <Route path="/profileview" component={ProfileView} />

          <Route path="/register" component={Register} />
          */}
          <Route path="/dashboard" component={MainDashboard} />
          {/*  */}
          <Route component={Error} />
          
        </Switch>
        <Footer/>
      </Router>
     

      <ToastContainer />
    </div>
  );
};

export default App;
