import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';

// Image
import favIcon from '../../../assets/img/applogo.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

import Header from '../../../components/Layout/Header';
import OffWrap from '../../../components/Layout/Header/OffWrap';

import bannerbg from '../../../assets/img/breadcrumbs/6.jpg';
import Footer from '../../../components/Layout/Footer/Footer';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
// import CountryList from './CountryList';

import clientService from '../../service/clientService';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../../../firebase/config';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import Whatsapp from '../Whatsapp';

import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import { Form, Button, FormSelect, FormControl } from 'react-bootstrap';
import { Timestamp } from 'firebase/firestore';

export default function EditReminder() {
  const { id } = useParams();

  const [nation, setNation] = useState([]);
  const [nationselect, setNationSelect] = useState('');
  const [type, setType] = useState([]);
  const [typesel, setTypeSel] = useState('');
  const [issuedate, setIssueDate] = useState({ seconds: 0, nanoseconds: 0 });
  const [expirydate, setExpiryDate] = useState({ seconds: 0, nanoseconds: 0 });
  const remCollectionRef = collection(db, 'docReminder');
  const [filterdata, setFilterData] = useState([]);

  const [rem, setRem] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'));

  const userId = userData.id;
  const username = userData.name;

  useEffect(() => {
    const handlePopState = (event) => {
      const userConfirmed = window.confirm("Are you sure you want to go back?");
      if (!userConfirmed) {
        history.go(1); // Move forward in history stack to cancel back navigation
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  useEffect(() => {
    getNation();
    getAllDocType();
    getAllDocRem();
  }, []);

  useEffect(() => {
    if (id !== undefined && id !== '') {
      editData();
    }
  }, []);


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);

  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };

  console.log(additionalData);

  const editData = async () => {
    try {
      const res = await clientService.getDocReminderOne(id);
      console.log(res.data())

      const data = res.data();
      console.log(data);

      setNationSelect(data?.nationality?.id);
      setTypeSel(data?.DocType?.id);
      setIssueDate(data?.IssueDate);

      setExpiryDate(data?.ExpairyDate);
      setAdditionalData(data?.additionalData);

      handleType(data?.DocType?.id);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(additionalData)

  const getAllDocRem = async () => {
    try {
      const data = await clientService.getAllRem();
      setRem(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getNation = async () => {
    const response = await clientService.getNationalityAll()

    const dataString = response.docs; // Adjust this if necessary based on actual API response structure

    const sortedData = dataString
      .map((doc) => ({ ...doc.data(), id: doc.id })) // Assuming each doc has an 'id' and other properties
      .sort((a, b) => (a.name || '').localeCompare(b.name || '')); // Sort alphabetically by 'name'

    setNation(sortedData);
  };

  const getAllDocType = async () => {
    const data = await clientService.getAllType();
    setType(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const handleNatSelect = async (selectedValue) => {
    try {
      setNationSelect(selectedValue);
      // handlePricing(selectedValue, attestselect, certitypesel);
    } catch (error) {
      console.error('Error handling nationality selection:', error);
    }
  };

  console.log(filterdata);

  const handleType = async (selectedValue) => {
    setTypeSel(selectedValue);

    if (selectedValue != null) {
      try {
        const data = await clientService.getAllFields(selectedValue);

        const filteredData = data.docs.filter(
          (doc) => doc.data().docType === selectedValue
        );

        // setAdditionalData([]);
        setFilterData(
          filteredData.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      } catch (error) {
        console.error('Error fetching pricing:', error);
      }
    }
  };

  const handleIssuedate = (e) => {
    const selectedDate = e.target.value;
    const timestampInSeconds = Date.parse(selectedDate) / 1000;
    // setIssueDate(e.target.value);
    setIssueDate({ seconds: timestampInSeconds, nanoseconds: 0 });
  };
  const handleExpirydate = (e) => {
    const selectedDate = e.target.value;
    const timestampInSeconds = Date.parse(selectedDate) / 1000;
    // setExpiryDate(e.target.value);
    setExpiryDate({ seconds: timestampInSeconds, nanoseconds: 0 });
  };

  const handleAdditionalData = (index, fieldData) => {
    let newData;
    if (fieldData.type === 'date') {
      // Append the default time to the date string
      const formattedDate = fieldData.value + ' 00:00:00.000';
      newData = [...additionalData];
      // Update the value of the date field with the formatted date
      newData[index] = { ...fieldData, value: formattedDate };
    } else {
      newData = [...additionalData];
      newData[index] = fieldData;
    }

    setAdditionalData(newData);
  };

  const uploadFileToFirestore = async (file, index) => {
    try {
      const storageRef = ref(storage, 'image/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
          }
        },
        (error) => {
          console.error('Error uploading file:', error);
        },
        () => {
          // Upload completed successfully, get download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // Construct file data with type, label, and file URL
            const label =
              filterdata.find((field) => field.type === 'file')?.label ||
              'File';

            const fileData = {
              type: 'file',
              label: file.name,
              file: downloadURL,
            };

            // Update additionalData with the file data
            const newData = [...additionalData];
            newData[index] = fileData;
            setAdditionalData(newData);
          });
        }
      );
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  console.log(additionalData);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const currentDate = Timestamp.now();

      const data = {
        nationality: {
          name: nation.find((nation) => nation.id === nationselect)?.name,
          id: nationselect,
        },
        DocType: {
          name: type.find((type) => type.id === typesel)?.name,
          id: typesel,
        },

        IssueDate: issuedate,
        ExpairyDate: expirydate,
        //additional info
        additionalData: additionalData,

        requestedStatus: 'requested',

        user: {
          name: username,
          userId: userId,
        },
        createdDate: currentDate,
        updatedDate: currentDate,
      };
      const res = await clientService.updateDocReminder(id, data);
      toast.success('Document Remainder data updated successfully');
      setTimeout(() => {
        // window.location('/')
        history.push('/dashremview');
        setLoading(false);
      }, 1000);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Error: Please try again later.');
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />

      <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link to="/dashremview" className="active">
                        Document Reminder
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="active">
                        Update
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="#">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                            <ul className="cart-icon-product-list">
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-info-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/shop/cart">About Us</Link>
                                  <br />
                                  {/* <span className="quantity">1 × $30.00</span> */}
                                </div>
                                <div className="product-image"></div>
                              </li>
                            </ul>

                            <div className="cart-btn text-center">
                              <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link>
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}

                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="course-overview">
                <div className="inner-box">
                  <div
                    id="rs-blog"
                    className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            id="rs-about"
                            className="rs-about style1 pb-100 md-pb-70"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-11 order-last">
                                  <div className="notice-bord style1">
                                    <div className="rs-checkout orange-style pt-100 pb-100 md-pt-70 md-pb-70">
                                      <div className="container">

                                        <div className="full-grid">
                                          <form id="checkout-form">
                                            <div className="billing-fields">
                                              <div className="checkout-title">
                                                <h3>Basic details</h3>
                                              </div>
                                            </div>
                                            <div className="form-content-box">
                                              <div className="row">
                                                <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>
                                                          Nationality*
                                                        </label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          value={nationselect}
                                                          onChange={(e) =>
                                                            handleNatSelect(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="form-control"
                                                        >
                                                          <option value="">
                                                            Select Nationality
                                                          </option>

                                                          {nation?.map(
                                                            (nat, index) => (
                                                              <option
                                                                key={index}
                                                                value={nat.id}
                                                              >
                                                                {nat.name}
                                                              </option>
                                                            )
                                                          )}
                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-3">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>
                                                          Document Type*
                                                        </label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          value={typesel} // Assuming typesel is a state variable
                                                          onChange={(e) =>
                                                            handleType(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="form-control"
                                                        >
                                                          <option value="">
                                                            Select Document Type
                                                          </option>

                                                          {type?.map(
                                                            (type, index) => (
                                                              <option
                                                                key={index}
                                                                value={type.id}
                                                              >
                                                                {type.name}{' '}
                                                              </option>
                                                            )
                                                          )}
                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-3">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>
                                                          Issue Date*
                                                        </label>
                                                        <input
                                                          type="date"
                                                          className="form-control"
                                                          value={
                                                            issuedate.seconds
                                                              ? new Date(
                                                                issuedate.seconds *
                                                                1000
                                                              )
                                                                .toISOString()
                                                                .split('T')[0]
                                                              : ''
                                                          }
                                                          onChange={
                                                            handleIssuedate
                                                          }
                                                          required
                                                        />
                                                        {/* <CountryList /> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-3">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>
                                                          Expiry Date*
                                                        </label>
                                                        <input
                                                          className="form-control"
                                                          type="date"
                                                          value={
                                                            expirydate.seconds
                                                              ? new Date(
                                                                expirydate.seconds *
                                                                1000
                                                              )
                                                                .toISOString()
                                                                .split('T')[0]
                                                              : ''
                                                          }
                                                          onChange={
                                                            handleExpirydate
                                                          }
                                                          required
                                                        />
                                                        {/* <CountryList /> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                {filterdata[0]?.labels?.map(
                                                  (field, index) => (
                                                    <div key={index}>
                                                      <Form.Label>
                                                        {field.label}
                                                      </Form.Label>
                                                      {field.type === 'text' ? (
                                                        <FormControl
                                                          type="text"
                                                          className="form-control"
                                                          value={
                                                            additionalData[
                                                              index
                                                            ]?.value || ''
                                                          }
                                                          onChange={(e) =>
                                                            handleAdditionalData(
                                                              index,
                                                              {
                                                                label:
                                                                  field.label,
                                                                type: field.type,
                                                                value:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            )
                                                          }
                                                        />
                                                      ) : field.type ===
                                                        'file' ? (
                                                        <div>
                                                          <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              uploadFileToFirestore(
                                                                e.target
                                                                  .files[0],
                                                                index
                                                              )
                                                            }
                                                          />
                                                          {additionalData[index]
                                                            ?.file && (
                                                              <a href={additionalData[index]?.file}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                              >
                                                                Download File
                                                              </a>
                                                            )}
                                                        </div>
                                                      ) : field.type ===
                                                        'date' ? (
                                                        <FormControl
                                                          type="date"
                                                          className="form-control"
                                                          value={additionalData[index]?.value || ''}
                                                          onChange={(e) =>
                                                            handleAdditionalData(
                                                              index,
                                                              {
                                                                label:
                                                                  field.label,
                                                                type: field.type,
                                                                value:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            )
                                                          }
                                                        />
                                                      ) : null}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>

                                            <div className="payment-method mt-40 md-mt-20">
                                              <div className="top-area">
                                                <div className="p-msg">
                                                  {' '}
                                                  Your personal data will be
                                                  used to process your order,
                                                  support your experience
                                                  throughout this website, and
                                                  for other purposes described
                                                  in our privacy policy.
                                                </div>
                                              </div>
                                              {loading ? (
                                                <div
                                                  className="spinner-border text-primary"
                                                  role="status"
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="bottom-area">
                                                  <p className="mt-14"></p>
                                                  <button
                                                    className="btn-shop orange-color"
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                  >
                                                    Update
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </form>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
