import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import clientService from '../../../pages/service/clientService';

export default function Reminders({comid}) {
  const [remList, setRemList] = useState([]);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData.id;
  console.log(comid)

  useEffect(() => {
    getAllDocRem();
  }, []);

  const getAllDocRem = async () => {
    try {
      console.log(userId);
      const data = await clientService.getsingleDocRem(comid);
      setRemList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const calculateDaysRemaining = (expiryDate) => {
    const expiryTime = expiryDate.getTime();
    const currentTime = new Date().getTime();
    const timeDiff = expiryTime - currentTime;
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining;
  };

  

  
  const deleteRem=async(id)=>{
    console.log(id)
    console.log(`deleted id ${id}`)
    const res= await clientService.deleteRemainder(id)
    getAllDocRem()
    
}

  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <div
            id="rs-blog"
            className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {remList?.map((item, index) => {
                    const expiryDate = new Date(
                      item.ExpairyDate.seconds * 1000
                    );
                    console.log(item.ExpairyDate);
                    const daysRemaining = calculateDaysRemaining(expiryDate);
                    return (
                      <div className="events-short mb-30">
                        <div
                          className={`date-part ${
                            daysRemaining >= 10 ? 'bgc4' : ' bgc2 '
                          }`}
                        >
                          <span className="month">Days</span>
                          <div className="date">{daysRemaining}</div>
                        </div>
                        <div className="content-part">
                          <div className="categorie">
                             <h4 className="title mb-0">
                            <Link to="#">{item.DocType.name}</Link>
                          </h4>
                            <Link to="#">Expired On</Link> &amp;{' '}
                            <Link to="#">
                              {new Date(
                                item.ExpairyDate.seconds * 1000
                              ).toLocaleDateString()}
                            </Link>
                          </div>
                          <p>Nationality: {item.nationality.name}</p>
                          <p>Issue Date :{new Date(item.IssueDate.seconds * 1000).toLocaleDateString()}</p>
                          <p>Issue Date :{new Date(item.ExpairyDate.seconds * 1000).toLocaleDateString()}</p>
                        </div>

                        <div className="d-flex flex-row">
                          <div className="p-2">
                            {/* /editschengen/R7zTHyDbr1YArG8Kplxb */}
                            <Link to={`/doc/update/${item.id}`}>
                              <button
                                type="button"
                                className="btn btn-outline-dark btn-sm"
                              >
                                Edit
                              </button>
                            </Link>
                          </div>
                          <div className="p-2">
                            <button
                              type="button"
                              className="btn btn-outline-dark btn-sm"
                              onClick={() => {
                                if (window.confirm('Do you want to delete ?')) {
                                  deleteRem(item.id)
                                }
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
