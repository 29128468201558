import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';

// Image
import favIcon from '../../../assets/img/applogo.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

import HeaderStyleSix from '../../../components/Layout/Header/HeaderStyleSix';
import OffWrap from '../../../components/Layout/Header/OffWrap';

import bannerbg from '../../../assets/img/breadcrumbs/6.jpg';
import Footer from '../../../components/Layout/Footer/Footer';
import Whatsapp from '../Whatsapp';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import CountryList from './CountryList';

import clientService from '../../service/clientService'
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../../../firebase/config';

import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  uploadString,
} from 'firebase/storage';
import { Form, Button, FormSelect } from 'react-bootstrap'
import { Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import mobileLogo from '../../../assets/img/logo/logo-green.png';


export default function Index() {

  const [nation, setNation] = useState([])
  const [nationselect, setNationSelect] = useState('')
  const [attest, setAttest] = useState([])
  const [attestselect, setAttestSelect] = useState('')
  const [address, setAddress] = useState('')
  const [contact, setContact] = useState()
  const [error, setError] = useState('');
  const [certitype, setCertiType] = useState([])
  const [certitypesel, setCertiTypeSel] = useState('')
  // const [doc1, setDoc1] = useState(null)
  // const [doc2, setDoc2] = useState(null)
  // const [doc3, setDoc3] = useState(null)
  const [documents, setDocuments] = useState([{ id: 1, file: null, progress: 0 }]);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [pricedata, setPriceDtata] = useState([])


  const certificateAttestationCollectionRef = collection(db, 'certificateAttestation')


  const userData = JSON.parse(localStorage.getItem('userData'))
  console.log(userData)
  const userId = userData.id
  const username = userData.name
  console.log(userId, username)
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handlePopState = (event) => {
      const userConfirmed = window.confirm("Are you sure you want to go back?");
      if (!userConfirmed) {
        history.go(1); // Move forward in history stack to cancel back navigation
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  useEffect(() => {
    getNation()
    getAllCertiType()
    getAllAttest()
  }, [])

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);
  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };

  const getNation = async () => {
    const response = await clientService.getNationalityAll()

    const dataString = response.docs; // Adjust this if necessary based on actual API response structure

    const sortedData = dataString
      .map((doc) => ({ ...doc.data(), id: doc.id })) // Assuming each doc has an 'id' and other properties
      .sort((a, b) => (a.name || '').localeCompare(b.name || '')); // Sort alphabetically by 'name'

    setNation(sortedData);
  };
  console.log(nation)

  const getAllAttest = async () => {
    const data = await clientService.getAllAttest()
    setAttest(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  }

  console.log("attest", attest)
  const getAllCertiType = async () => {
    const data = await clientService.getAllCertiType()
    setCertiType(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  }
  // console.log(certitype)


  const handleNatSelect = async (selectedValue) => {
    try {
      setNationSelect(selectedValue);
      handlePricing(selectedValue, attestselect, certitypesel);
    } catch (error) {
      console.error('Error handling nationality selection:', error);
    }
  };

  const handleAttest = async (selectedValue) => {
    try {
      setAttestSelect(selectedValue);
      handlePricing(nationselect, selectedValue, certitypesel);
    } catch (error) {
      console.error('Error handling attestation selection:', error);
    }
  };
  const handleCertiType = async (selectedValue) => {
    setCertiTypeSel(selectedValue);
    handlePricing(nationselect, attestselect, selectedValue);
  }

  // console.log("attest",attestselect)
  const handleAddress = async (e) => {
    setAddress(e.target.value)
  }

  const handlecontact = async (e) => {
    e.preventDefault()
    setContact(e.target.value)
    const phoneNumberPattern = /^\d{9}$/;

    if (!phoneNumberPattern.test(e.target.value)) {
      setError('Please enter a valid 9-digit phone number.');
    } else {
      setError('');
    }
  }


  const handleDeleteDocument = (index) => {
    const newDocuments = documents.filter((_, i) => i !== index);
    setDocuments(newDocuments);
  };

  const uploadDocument = async (doc, index) => {
    const imageRef = ref(storage, `image/${doc.name}`);
    const uploadTask = uploadBytesResumable(imageRef, doc);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(prog);
        },
        (err) => {
          console.log(err);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve(url);
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
        }
      );
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (documents.some(doc => doc.file == null)) return;



    try {
      setShowProgress(true);
      // const doc1Url = await uploadDocument(doc1);
      // const doc2Url = await uploadDocument(doc2);
      // const doc3Url = await uploadDocument(doc3);
      const urls = await Promise.all(documents.map((doc, index) => uploadDocument(doc.file, index)))
      const currentDate = Timestamp.now();
      const data = documents.reduce((acc, doc, index) => {
        acc[`upload${index + 1}`] = urls[index];
        acc[`uploadName${index + 1}`] = doc.file.name;
        return acc;
      }, {
        nationality: {
          name: nation.find((nation) => nation.id === nationselect)?.name,
          id: nationselect,
        },
        certificateType: {
          name: certitype.find((certitype) => certitype.id === certitypesel)?.name,
          id: certitypesel,
        },
        attestType: {
          name: attest.find((attest) => attest.id === attestselect)?.name,
          id: attestselect,
        },
        collectionAddress: address,
        requestedStatus: 'requested',

        user: {
          name: username,
          userId: userId
        },
        createdDate: currentDate,
        updatedDate: currentDate,
        contactNumber: contact,

      });
      console.log(data)
      const res = await addDoc(certificateAttestationCollectionRef, data);

      console.log("certificateattestation data added successfully");
      toast.success('certificateattestation data added successfully')
      console.log(" data added successfully", res);
      setTimeout(() => {
        // window.location('/')
        history.push('/dashcertiview')
        setLoading(false)

      }, 1000)
      setLoading(false)
    } catch (error) {
      console.log(error);
      toast.error('Error: Please try again later.');
      setLoading(false)

    } finally {
      setProgress(false);
    }
  };


  const handleDocumentChange = (index, event) => {
    const newDocuments = [...documents];
    newDocuments[index].file = event.target.files[0];
    setDocuments(newDocuments);
  };

  const handleAddDocument = () => {
    setDocuments([...documents, { id: documents.length + 1, file: null, progress: 0,isAdded: true  }]);
  };


  const handlePricing = async (nationId, attestId, certiId) => {

    console.log("nation", nationId, "attest", attestId, "certi", certiId);
    // if (selectedValue === 'nationselect') {
    //     setNationSelect(selectedValue);
    // } else if (selectedValue === 'certitypesel') {
    //     setCertiTypeSel(selectedValue);
    // } else if (selectedValue === 'attestselect') {
    //     setAttestSelect(selectedValue);
    // }

    if (nationId != null && attestId != null && certiId != null) {
      try {
        // Call getPricing with the updated state values
        const data = await clientService.getPricing(nationId, attestId, certiId);
        console.log("inside getPricing");
        console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setPriceDtata(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.error('Error fetching pricing:', error);
      }
    }


  };




  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />

      <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link to="/dashcertiview" className="active">
                        Certificate Attestation
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="active">
                        Create
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="#">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                            <ul className="cart-icon-product-list">
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-info-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/shop/cart">About Us</Link>
                                  <br />
                                  {/* <span className="quantity">1 × $30.00</span> */}
                                </div>
                                <div className="product-image"></div>
                              </li>
                            </ul>

                            <div className="cart-btn text-center">
                              <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link>
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}

                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="course-overview">
                <div className="inner-box">
                  <div
                    id="rs-blog"
                    className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            id="rs-about"
                            className="rs-about style1 pb-100 md-pb-70"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-11 order-last">
                                  <div className="notice-bord style1">
                                    <div className="rs-checkout orange-style pt-100 pb-100 md-pt-70 md-pb-70">
                                      <div className="container">
                                        <div className="full-grid">
                                          <form id="checkout-form">
                                            <div className="billing-fields">
                                              <div className="checkout-title">
                                                <h3>Basic details</h3>
                                              </div>
                                            </div>
                                            <div className="form-content-box">
                                              <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Nationality *</label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          value={nationselect}
                                                          onChange={(e) => handleNatSelect(e.target.value)}
                                                          className="form-control"
                                                          required
                                                        >
                                                          <option value="">Select Nationality</option>
                                                          {nation?.map((nat, index) => (
                                                            <option key={index} value={nat.id}>
                                                              {nat.name}
                                                            </option>
                                                          ))}
                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-4">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Document Type*</label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          value={certitypesel}
                                                          onChange={(e) => handleCertiType(e.target.value)}
                                                          className="form-control"
                                                          required
                                                        >
                                                          <option value="">Select Document Type</option>

                                                          {certitype?.map((type, index) => (
                                                            <option key={index} value={type.id}>
                                                              {type.name}{' '}
                                                            </option>
                                                          ))}
                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-4">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Attestation Type*</label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          value={attestselect}
                                                          onChange={(e) => handleAttest(e.target.value)}
                                                          className="form-control"
                                                          required
                                                        >
                                                          <option value="">Select Attestation Type</option>

                                                          {attest?.map((att, index) => (
                                                            <option key={index} value={att.id}>
                                                              {att.name}
                                                            </option>
                                                          ))}
                                                        </FormSelect>
                                                        <div className='p-3' style={{ marginLeft: '-250px', marginTop: '20px' }}>
                                                          {pricedata?.map((pri) => (
                                                            <h5 style={{ color: 'red' }}>
                                                              Price: {pri.price} ADE
                                                            </h5>
                                                          ))}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="additional-fields">
                                              <div className="form-content-box">
                                                <div className="checkout-title">
                                                  <h3> Documents</h3>
                                                </div>
                                                <div className="row">
                                                  <div className='row'>
                                                    {documents.map((doc, index) => (
                                                      <div className="col-md-6 col-sm-6 col-xs-12" key={doc.id}>
                                                        <div className="form-group">
                                                          <label>Document {index + 1}*</label>
                                                          <input
                                                            name={`document-${index + 1}`}
                                                            className="form-control-mod margin-bottom"
                                                            type="file"
                                                            required
                                                            onChange={(e) => handleDocumentChange(index, e)}
                                                          />
                                                          {showProgress && <h3>uploaded {doc.progress} %</h3>}
                                                          {doc.isAdded && (
                                                            <button type="button"  onClick={() => handleDeleteDocument(index)}>Delete</button>
                                                          )}
                                                        </div>
                                                      </div>
                                                    ))}

                                                    <div className='mb-3' >

                                                      <button style={{ fontSizeAdjust: '5rem' }} type="button"  onClick={handleAddDocument}>+</button>

                                                    </div>
                                                  </div>


                                                  <div className="row">

                                                    <div className="d-flex form-group col-lg-12 mb-25">
                                                      <div className="form-control" style={{ width: '70px', maxWidth: '70px', paddingRight: '0', paddingLeft: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span className="text-center">+971</span>
                                                      </div>
                                                      <input
                                                        name="hnumber"
                                                        className="form-control"
                                                        type="number"
                                                        placeholder="Enter number here"
                                                        required
                                                        value={contact}
                                                        style={{ flex: '1' }}
                                                        onChange={handlecontact}
                                                      />
                                                      {error && <div style={{ color: 'red' }}>{error}</div>}
                                                    </div>

                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Collection Address</label>
                                                        <textarea
                                                          placeholder="Notes about your order, e.g. special notes for delivery."
                                                          value={address}
                                                          onChange={handleAddress}
                                                          required
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="payment-method mt-40 md-mt-20">
                                              <div className="top-area">
                                                <div className="p-msg">
                                                  {' '}
                                                  Your personal data will be used to process your order,
                                                  support your experience throughout this website, and for
                                                  other purposes described in our privacy policy.
                                                </div>
                                              </div>
                                              {loading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                                </div>
                                              ) : (
                                                <div className="bottom-area">
                                                  <p className="mt-14"></p>
                                                  <button
                                                    className="btn-shop orange-color"
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                  >
                                                    Request
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
