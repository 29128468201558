import React, { useEffect, useState } from 'react';
import clientService from '../../../pages/service/clientService';

import NoImage from '../../../assets/img/no-image.png';
import { Link } from 'react-router-dom';

export default function Shengen({comid}) {
  const [schengenList, setSchengenList] = useState([]);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const userId = userData.id;

  useEffect(() => {
    getSchengenVisa();
  }, []);

  const getSchengenVisa = async () => {
    const data = await clientService.getschengenVisasAll(comid);
    setSchengenList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteSchengenVisa=async(id)=>{
    console.log(id)
    console.log(`deleted id ${id}`)
    const res= await clientService.deleteSchengen(id)
    getSchengenVisa()
    
}

  return (
    <div className="blog-deatails">
      {schengenList?.length === 0 ? (
        <>
          <span style={{ alignItems: 'center' }}>
            <img src={NoImage} style={{ width: '25%' }} />
          </span>
        </>
      ) : (
        schengenList?.map((item) => (
          <div className="blog-full">
            <blockquote>
              <p>{item?.noOfMembers} Members</p>
              <p>Nationality : {item?.nationality?.name} </p>
              <p>Visa Purpose : {item?.visaPurpose?.name} </p>
              <p>Visa Status{item?.visaStatus?.name}</p>
              <p>Country : {item?.country?.name} </p>
              <p>{item?.contactNumber}</p>

              <ul className="single-post-meta">
                <li>
                  <span className="p-date">
                    {' '}
                    <i className="fa fa-calendar-check-o"></i> April 6, 2022{' '}
                  </span>
                </li>

                <li className="post-comment">
                  {' '}
                  <i className="fa fa-comments-o"></i> Requested
                </li>
              </ul>
              <div className="d-flex flex-row">
                <div className="p-2">
                  {/* /editschengen/R7zTHyDbr1YArG8Kplxb */}
                  <Link to={`/editfamilyvisa/${item.id}`}>
                    <button type="button" className="btn btn-outline-dark btn-sm">
                      Edit
                    </button>
                  </Link>

                </div>
                <div className="p-2">
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => {
                      if (window.confirm('Do you want to delete ?')) {
                        deleteSchengenVisa(item.id)
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </blockquote>
          </div>
        ))
      )}
    </div>
  );
}
