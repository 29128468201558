import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesSingleTwo = (props) => {
  const { categoriesClass, iconImg, catLink, title, courseQuantity, count } =
    props;

  return (
    <Link
      className={categoriesClass ? categoriesClass : 'categories-item'}
      to={catLink ? catLink : '/course-categories'}
    >
      <div className="icon-part">
        <img src={iconImg} alt={title} />
      </div>
      <div className="content-part">
        <div class="d-flex bd-highlight">
          <div class="p-2 flex-grow-1 bd-highlight">
            {' '}
            <h4 className="title">{title ? title : 'General Education'}</h4>
            <span className="courses">
              {courseQuantity ? courseQuantity : '05'}{' '}
            </span>
          </div>

          <div class="p-3 bd-highlight" style={{ backgroundColor: '#f3f8f9' }}>
            <span style={{ fontSize: '35px' }}>{count}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CategoriesSingleTwo;
