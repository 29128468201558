import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  const bgStyle = {
    position: 'fixed',
    height: '100px',
    bottom: 40,
    width: '100%',
  };
  return (
    <footer className="bg-body-tertiary text-center" style={bgStyle}>
      {/* Grid container */}
      <div className="container p-4 pb-0" style={{ overflow: 'hidden' }}>
        <Carousel indicators={false} controls={false} interval={2000}>
          <Carousel.Item>
            <div className="d-flex justify-content-around">
              <img
                src="tasheel.png"
                className="d-block"
                alt="Icon 1"
                height="75"
                width="75"
              />
              <img
                src="vfsgloba.png"
                className="d-block"
                alt="Icon 2"
                height="75"
                width="75"
              />
              <img
                src="DubaiEmigration.png"
                className="d-block"
                alt="Icon 3"
                height="75"
                width="75"
                style={{ objectFit: 'cover' }}
              />
              <img
                src="amer.png"
                className="d-block"
                alt="Icon 4"
                height="75"
                width="75"
              />
              <img
                src="sky_aurora.png"
                className="d-block"
                alt="Icon 5"
                height="75"
                width="75"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex justify-content-around">
              <img
                src="tasheel.png"
                className="d-block"
                alt="Icon 1"
                height="75"
                width="75"
              />
              <img
                src="vfsgloba.png"
                className="d-block"
                alt="Icon 2"
                height="75"
                width="75"
              />
              <img
                src="DubaiEmigration.png"
                className="d-block"
                alt="Icon 3"
                height="75"
                width="75"
              />
              <img
                src="amer.png"
                className="d-block"
                alt="Icon 4"
                height="75"
                width="75"
              />
              <img
                src="sky_aurora.png"
                className="d-block"
                alt="Icon 5"
                height="75"
                width="75"
              />
            </div>
          </Carousel.Item>
        </Carousel>
        {/* Section: Social media */}
      </div>
      {/* Grid container */}

      {/* Copyright */}
      <div
        className="text-center p-3"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
      >
        © 2020 Copyright
        <a className="text-body" href="https://luzidcraft.com/" target="_blank">
          Luzidcraft
        </a>
      </div>
      {/* Copyright */}
    </footer>
  );
};

export default Footer;
