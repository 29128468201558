import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAXxQNDItj_5k-a1f8BuPqKhS9bMcdTbw4',
  authDomain: 'sky-aroura.firebaseapp.com',
  projectId: 'sky-aroura',
  storageBucket: 'sky-aroura.appspot.com',
  messagingSenderId: '707820501746',
  appId: '1:707820501746:web:980938ca386b8a3113ee6b',
  measurementId: 'G-EYJHCB6Y8Z',
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

const db = getFirestore(app);
export { db, storage };
