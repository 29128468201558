import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../components/Layout/Header/OffWrap';


import favIcon from '../../assets/img/fav.png';

// Categories Icon
import icon1 from '../../assets/img/categories/icons/1.png';
import icon5 from '../../assets/img/categories/icons/5.png';
import icon7 from '../../assets/img/categories/icons/7.png';

import SectionTitle from '../../components/Common/SectionTitle';
import { Link, useHistory } from 'react-router-dom';
import CategoriesSingleTwo from '../../components/Categories_/CategoriesSingleTwo';
import clientService from '../../pages/service/clientService';

import bgImg from '../../assets/img/bg.png';


import Footer from './Footer';
import Header from './Header';

import Whatsapp from './Whatsapp';

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
  height: '100vh',
  width: '100vw',

  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  margin: 0,
  padding: 0,
  marginTop: '60px',
};

export default function MainDashboard() {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData?.id;

  useEffect(() => {
    getAllDocRem();
    getAllcertiAttestation();
    getfamilyVisa();
    getSchengenVisa();
  }, []);

  const [user, setUser] = useState({})

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    setUser(user)
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);
  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      window.location.reload();
    }
  };

  const [remList, setRemList] = useState([]);

  const getAllDocRem = async () => {
    try {
      console.log(userId);
      const data = await clientService.getsingleDocRem(userId);
      setRemList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // console.log(remList);

  const [attestList, setAttestList] = useState([]);

  const getAllcertiAttestation = async () => {
    try {
      const data = await clientService.getAllcertificateAttestation(userId);
      setAttestList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) { }
  };

  // console.log(attestList)

  const [familylist, setFamilyList] = useState([]);

  const getfamilyVisa = async () => {
    try {
      console.log(userId);
      const data = await clientService.getAllFamily(userId);
      console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setFamilyList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(familylist)

  const [schengenList, setSchengenList] = useState([]);

  const getSchengenVisa = async () => {
    const data = await clientService.getschengenVisasAll(userId);
    setSchengenList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  console.log(schengenList);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      {/* //setting Navbar */}
      <Whatsapp />

      <div className="rs-categories gray-bg style1" style={bgStyle}>
        <div className="container">
          <div className="row y-middle mb-50 md-mb-20">
            <div className="col-md-6 sm-mb-30 mt-20">
              <div className=" text-center"
              // style={{ marginLeft: '400px' }}
              >
                <div style={{ float: 'left', textAlign: 'left', width: '50%' }}>
                  <SectionTitle
                    sectionClass="sec-title"
                    subtitleClass="sub-title primary"
                    titleClass="title mb-0"
                    title="Our Service"
                  />
                </div>
                <div style={{ float: 'right', textAlign: 'right', width: '100%',marginRight:'-500px' }}>
                  <h3 style={{ color: 'black' ,fontfamily: 'Times New Roman, Times, serif'}}>Hi {user?.name} welcome back!!!</h3>
                </div>
              </div>
            </div>
          

            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search">
                        {/* <Link to="#" onClick={searchModalAdd} className="rs-search" href="#">
													<i className="flaticon-search"></i>
												</Link> */}
                      </li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="/my-account">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '35px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                            {/* <ul className="cart-icon-product-list">
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-info-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/about">About Us</Link>
                                </div>
                              </li>
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-user-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/my-account">Profile</Link>
                                </div>
                              </li>
                            </ul> */}

                            <div className="cart-btn text-center">
                              {/* <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link> */}
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}
                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 mb-30">
              {/* <Link to="visa/family/request"> */}
              <CategoriesSingleTwo
                categoriesClass="categories-item"
                title="Document Reminder"
                courseQuantity="Remind Own time"
                iconImg={icon1}
                catLink="/dashremview"
                count={remList?.length}
              />

              {/* </Link> */}
            </div>
            <div className="col-lg-6 col-md-6 mb-30">
              <CategoriesSingleTwo
                categoriesClass="categories-item"
                title="Family Visa"
                courseQuantity="Family Visa request"
                iconImg={icon7}
                catLink="/dashfamilyview"
                count={familylist?.length}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-30">
              <CategoriesSingleTwo
                categoriesClass="categories-item"
                title="Certificate Attestation"
                courseQuantity="Attestation"
                iconImg={icon5}
                catLink="/dashcertiview"
                count={attestList?.length}
              />
            </div>
            <div className="col-lg-6 col-md-6 mb-30">
              <CategoriesSingleTwo
                categoriesClass="categories-item"
                title="Shengen Visa"
                courseQuantity="Shengen Visa"
                iconImg={icon1}
                catLink="/dashschengenview"
                count={schengenList?.length}
              />
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}
