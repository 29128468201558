import React from 'react'
import Container from 'react-bootstrap/Container';
import {Navbar,Nav } from 'react-bootstrap';

const Header = () => {
    return (
      <div>
        <Navbar expand="lg" className="bg-body-secondary" fixed="top">
          <Container>
            <Navbar.Brand href="/">
              <img
                src="/applogo.png"
                className="navbar-brand-img h-100"
                alt="main_logo"
                height={'40px'}
                width={'40px'}
              />{' '}
              &nbsp;
              <img
                src="/appName.png"
                className="navbar-brand-img h-100"
                alt="main_logo"
                height={'100px'}
                width={'100px'}
              />{' '}
              &nbsp;
              {/* H I P R O */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {' '}
                {/* ml-auto will push the links to the right */}
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/about">About</Nav.Link>
                <Nav.Link href="/contact">Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
}

export default Header
