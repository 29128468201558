import React from 'react';
import Faq from './FaqSection';
import Blog from './BlogSection';
import Event from './EventSection';
import Courses from './CourseSection';
import Counter from './CounterSection';
import Service from './ServiceSection';
import Download from './DownloadSection';
import Testimonial from './TestimonialSection';
import WhyChooseUs from './WhyChooseUsSection';
import BrandFour from '../../components/Common/Brand/BrandFour';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerStyleEight from '../../components/Banner/BannerStyleEight';
import { Link } from 'react-router-dom';

const HomeTwelveMain = () => {
  return (
    <React.Fragment>
      <div className="row y-middle mt-50 mb-50 md-mb-30">
        <div className="col-md-6 sm-mb-30">
          <div
            className="rs-breadcrumbs breadcrumbs-overlay"
            style={{ color: 'black' }}
          >
            <div className="breadcrumbs-text black-color">
              <ul>
                <li>
                  <Link to="/dashboard" className="active">
                    Home
                  </Link>
                </li>

                <li>
                  <Link to="#" className="active">
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Banner-area-start */}
      {/* <BannerStyleEight /> */}
      {/* Banner-area-end */}

      {/* Brand-area-start */}
      {/* <BrandFour /> */}
      {/* Brand-area-end */}

      {/* Service-area-start */}
      <Service />
      {/* Service-area-end */}

      {/* Course-area-start */}
      {/* <Courses /> */}
      {/* Course-area-end */}

      {/* WhyChooseUs section start */}
      {/* <WhyChooseUs /> */}
      {/* WhyChooseUs section end */}

      {/* Counter section start */}
      {/* <Counter /> */}
      {/* Counter section end */}

      {/* Faq section start */}
      {/* <Faq /> */}
      {/* Faq section end */}

      {/* testmonial-area-start */}
      <Testimonial />
      {/* testmonial-area-end */}

      {/* Download-area-start */}
      <Download />
      {/* Download-area-end */}

      {/* Event-area-start */}
      {/* <Event /> */}
      {/* Event-area-end */}

      {/* blog-area-start */}
      {/* <Blog /> */}
      {/* blog-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup green-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeTwelveMain;
