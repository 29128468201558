import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSeven from '../../components/Service/SingleServiceSeven';

import serviceImg1 from '../../assets/img/services/home12/1.png';
import serviceImg2 from '../../assets/img/services/home12/2.png';
import serviceImg3 from '../../assets/img/services/home12/3.png';

import bgImg from '../../assets/img/bg/home12/services-bg.jpg';

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Service = () => {
  return (
    <React.Fragment>
      <div
        id="rs-services"
        className="rs-services home12-style"
        style={bgStyle}
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title4 text-center mb-50"
            subtitleClass="sub-title"
            // subtitle="Increase Your Skill"
            titleClass="title"
            title="Explore Our Service"
          />
          <div className="row">
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg1}
                serviceTitle="Family Visa Request"
                catLink="/course-categories"
                serviceDesc="A family visa request is a formal application submitted by an individual who wishes to sponsor a family member for immigration to a particular country. The purpose of such a request is typically to reunite family members who are separated due to geographical boundaries or immigration regulations."
              />
            </div>
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg3}
                serviceTitle="Certificate Attestation"
                catLink="/course-categories"
                serviceDesc="Certificate attestation is a process by which official documents, such as educational certificates, birth certificates, marriage certificates, and other legal documents, are authenticated for use in another country."
              />
            </div>

            <div className="col-lg-4">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg2}
                serviceTitle="Schengen Visa Request"
                catLink="/course-categories"
                serviceDesc="A Schengen Visa is a document that allows the holder to travel freely within the Schengen Area, which comprises 26 European countries that have abolished passport and other types of border control at their mutual borders. These countries include Austria, Belgium, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Slovakia, Slovenia, Spain, Sweden, and Switzerland."
              />
            </div>
          </div>
          {/* <div className="col-lg-12 text-center pt-44">
            <Link className="readon green-btn" to="/course">
              View All Subjects{' '}
            </Link>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Service;
