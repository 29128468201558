import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HeaderStyleSix from '../../../components/Layout/Header/HeaderStyleSix';
import Footer from '../../../components/Layout/Footer/Footer';
import Newsletter from '../../../components/Common/Newsletter';
import ScrollToTop from '../../../components/Common/ScrollTop';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import SearchModal from '../../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../../assets/img/applogo.png';

import Logo from '../../../assets/img/logo/dark-logo.png';
import footerLogo from '../../../assets/img/logo/lite-logo.png';
import mobileLogo from '../../../assets/img/logo/logo-green.png';


import bannerbg from '../../../assets/img/breadcrumbs/2.jpg';

import clientService from '../../service/clientService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { setTimeout } from 'core-js';
import Whatsapp from '../Whatsapp';
import { FormSelect } from 'react-bootstrap';


const Register = () => {

  const [nation, setNation] = useState([]);
  const [nationSelect, setNationSelect] = useState('');
  const [companySelected, setCompanySelected] = useState(false);
  const [comaddress, setCompanyAddress] = useState('');
  const [trade, setTrade] = useState('');
  const [location, setLocation] = useState('');
  const [emirates, setEmirates] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [comid, setComid] = useState('');
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState([])
  const history = useHistory()
  const [error, setError] = useState('');


  useEffect(() => {
    getNation();
  }, []);

  useEffect(() => {
    if (name && companySelected) {
      // Generate company ID
      const idPrefix = name.substring(0, 3).toUpperCase(); // Convert to uppercase
      const randomNumber = Math.floor(100000 + Math.random() * 900000);
      const generatedId = idPrefix + randomNumber;
      setComid(generatedId);
    }
  }, [name, companySelected]);

  const getNation = async () => {
    const response = await clientService.getNationalityAll();
    const dataString = response.docs; // Adjust this if necessary based on actual API response structure

    const sortedData = dataString
      .map((doc) => ({ ...doc.data(), id: doc.id })) // Assuming each doc has an 'id' and other properties
      .sort((a, b) => (a.name || '').localeCompare(b.name || '')); // Sort alphabetically by 'name'

    setNation(sortedData);
  };
  console.log(nation)

  // const handleNatSelect = (e) => {
  //   setNationSelect(e.target.value);
  // };

  const handleIndividualSel = () => {
    setCompanySelected(false);
    setName('');
    setNationSelect('');
    setPassword('');
    setPhone('');
    setCode('')
    setUsername('');
    setConfirm('');
    setMail('');
    setCity('');
    setCompanyAddress('');
    setComid('');
    setEmirates('');
  };

  const handleCompanySel = () => {
    setCompanySelected(true);
    setName('');
    setNationSelect('');
    setPassword('');
    setPhone('');
    setCode('')
    setUsername('');
    setConfirm('');
    setMail('');
    setCity('');
    setCompanyAddress('');
    setComid('');
    setEmirates('');
  };

  const handleNumber = async (e) => {
    e.preventDefault()
    setPhone(e.target.value)

    const phoneNumberPattern = /^\d{9}$/;

    if (!phoneNumberPattern.test(e.target.value)) {
      setError('Please enter a valid 9-digit phone number.');
    } else {
      setError('');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)


    if ((password === confirm) && (password.length >= 6)) {
      console.log('Password Match');

    } else {
      setPasswordMatch(false)
      toast.warning('password and confirm password does not match,password should be 8 characters ')
      setLoading(false);
      return

    }

    let data = {
      mailId: mail,
      name: name,
      nationality: nationSelect,
      password: password,
      phone: parseInt(phone),
      username: username,
    };

    if (!companySelected) {
      data = {
        ...data,
        companyAddress: '',
        companyCountry: '',
        companyLocation: '',
        companyTradeLc: '',
        emirates: '',
        city: '',
        isCompany: false,
        companyid: '',
        companyReqStatus:'',
        token:""

      };
    } else {
      data = {
        ...data,
        companyAddress: comaddress,
        // companyCounty: country,
        companyLocation: location,
        companyTradeLc: trade,
        emirates: emirates,
        city: city,
        isCompany: true,
        companyid: comid,
        companyReqStatus:'accept',
        token:"",
        companyCountry: "",

      };
    }

    console.log(data);

    try {
      const res = await clientService.addUser(data);
      toast.success('Registered Successfully');
      console.log('User added successfully', res);



      setTimeout(() => {
        history.push('/login')
        setLoading(false)
      }, 1000)
      setLoading(true)




    }
    catch (error) {
      console.error('Error occurred during registration:', error)
      setLoading(false)
      toast.error('some error occured,register not successful')
    }
  };


  const handleNation = async (value) => {
    const selectId = value
    setNationSelect(selectId)
    console.log(value)
    const result = await clientService.getCountryCode(value);
    console.log(result.data())
    setCode(result.data().code)



  }
  console.log(code)


  // console.log(nationSelect)

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />
      {/* <HeaderStyleSix
        parentMenu="home"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        mobileNormalLogo={mobileLogo}
        headerClass="full-width-header header-style1 home1-modifiy home12-modifiy"
        TopBar="enable"
        TopBarClass="topbar-area home11-topbar"
        phoneNumber="(+01) 999-999-4444"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      /> */}

      {/* breadcrumb-area-start */}
      {/* <SiteBreadcrumb
        pageTitle="Register"
        pageName="Register"
        breadcrumbsImg={bannerbg}
      /> */}
      {/* breadcrumb-area-End */}

      {/* Register Start */}
      <div className="register-section pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
          <div className="register-box">
            <div className="sec-title text-center mb-30">
              <h2 className="title mb-10">Create New Account</h2>
            </div>
            <div className="styled-form">
              <div id="form-messages"></div>
              <div className="form-group col-lg-12 mb-25">
                <label>
                  <input
                    type="radio"
                    name="individual"
                    value="individual"
                    defaultChecked
                    onChange={handleIndividualSel}
                    checked={!companySelected}
                  ></input>
                  Personal
                </label>
                <label>
                  <input
                    type="radio"
                    name="company"
                    value="company"
                    onChange={handleCompanySel}
                    checked={companySelected}
                  ></input>
                  Company
                </label>
              </div>

              {companySelected ? (
                <form id="contact-form" method="post">
                  <div className="row clearfix">
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={comaddress}
                        placeholder="CompanyAddress"
                        required
                        onChange={(e) => setCompanyAddress(e.target.value)}
                      />
                    </div>

                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={trade}
                        placeholder="Trade License Number"
                        required
                        onChange={(e) => setTrade(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={location}
                        placeholder="Location"
                        required
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                    {/* <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={country}
                        placeholder=" Country"
                        required
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </div> */}
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={emirates}
                        placeholder="Emirate"
                        required
                        onChange={(e) => setEmirates(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={city}
                        placeholder="City"
                        required
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>

                    <div className="form-group col-lg-12 mb-25">
                      <FormSelect
                        value={nationSelect}
                        onChange={(e) => handleNation(e.target.value)}
                        className="form-control"
                      >
                        <option value="">Select Nationality</option>

                        {nation.map((nat, index) => (
                          <option key={index} value={nat.id}>
                            {nat.name}
                          </option>
                        ))}
                      </FormSelect>
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={name}
                        placeholder="Name"
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="d-flex form-group col-lg-12 mb-25">
                      <div className="form-control" style={{ width: '70px', maxWidth: '70px', paddingRight: '0', paddingLeft: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span className="text-center">+971</span>
                      </div>
                      <input
                        type="number"
                        name="Phone"
                        value={phone}
                        placeholder="Phone"
                        required
                        className="form-control"
                        style={{ flex: '1' }}
                        onChange={handleNumber}
                      />
                      {error && <div style={{ color: 'red' }}>{error}</div>}

                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="email"
                        name="email"
                        value={mail}
                        placeholder="Email address "
                        required
                        onChange={(e) => setMail(e.target.value)}
                      />
                    </div>

                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="text"
                        value={username}
                        placeholder="User Name"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="password"
                        name="Confirm Password"
                        value={password}
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="Confirm Password"
                        value={confirm}
                        placeholder="Confirm Password"
                        required
                        onChange={(e) => setConfirm(e.target.value)}
                      />
                      {!passwordMatch && (
                        <p style={{ color: 'red' }}>Passwords do not Match</p>
                      )}
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 text-center">
                      {loading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="readon register-btn"
                          onClick={handleSubmit}
                        >
                          <span className="txt">Sign Up</span>
                        </button>
                      )}
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <div className="users">
                        Already have an account?{' '}
                        <Link to="/login">Sign In</Link>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <form id="contact-form" method="post">
                  <div className="row clearfix">
                    <div className="form-group col-lg-12 mb-25">
                      <select
                        value={nationSelect}
                        onChange={(e) => handleNation(e.target.value)}
                        className="form-control"
                      >
                        <option value="">Select Nationality</option>

                        {nation.map((nat, index) => (
                          <option key={index} value={nat.id}>
                            {nat.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="lname"
                        value={name}
                        placeholder="Name"
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="phone_number"
                        value={comid}
                        placeholder="Company Id (Optional)"
                        required
                        onChange={(e) => setComid(e.target.value)}
                      />
                    </div>
                    <div className="d-flex form-group col-lg-12 mb-25">
                      <div className="form-control" style={{ width: '70px', maxWidth: '70px', paddingRight: '0', paddingLeft: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span className="text-center">+971</span>
                      </div>
                      <input
                        type="number"
                        name="Phone"
                        value={phone}
                        placeholder="Phone"
                        required
                        className="form-control"
                        style={{ flex: '1' }}
                        onChange={handleNumber}
                      />
                      {error && <div style={{ color: 'red' }}>{error}</div>}

                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="email"
                        name="email"
                        value={mail}
                        placeholder="Email address "
                        required
                        onChange={(e) => setMail(e.target.value)}
                      />
                    </div>

                    {/* <div className="form-group col-lg-12">
                                 <input
                                   type="email"
                                  
                                   name="Confirm Password"
                                   value=""
                                   placeholder="Mail"
                                   required
                                   onChange={(e)=>(e.target.value)}
                                 />
                               </div> */}
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="Confirm Password"
                        value={username}
                        placeholder="User Name"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="password"
                        name="Confirm Password"
                        value={password}
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-12">
                      <input
                        type="text"
                        name="Confirm Password"
                        value={confirm}
                        placeholder="Confirm Password"
                        required
                        onChange={(e) => setConfirm(e.target.value)}
                      />
                      {!passwordMatch && (
                        <p style={{ color: 'red' }}>Passwords do not Match</p>
                      )}
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-sm-12 text-center">
                      {loading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="readon register-btn"
                          onClick={handleSubmit}
                        >
                          <span className="txt">Sign Up</span>
                        </button>
                      )}
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <div className="users">
                        Already have an account?{' '}
                        <Link to="/login">Sign In</Link>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Register End */}

      {/* <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      /> */}

      {/* <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      /> */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
}


export default Register;