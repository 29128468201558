import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HeaderStyleSix from '../../../components/Layout/Header/HeaderStyleSix';
import Footer from '../../../components/Layout/Footer/Footer';
import Newsletter from '../../../components/Common/Newsletter';
import ScrollToTop from '../../../components/Common/ScrollTop';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import SearchModal from '../../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../../assets/img/applogo.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import footerLogo from '../../../assets/img/logo/lite-logo.png';
import mobileLogo from '../../../assets/img/logo/logo-green.png';

import bannerbg from '../../../assets/img/breadcrumbs/inner7.jpg';
import Whatsapp from '../Whatsapp';

import clientService from '../../service/clientService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

const Login = () => {

    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const [loading, setLoading] = useState(false)
    const history=useHistory()




    const handlesubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        console.log(user, pass)
        try {
            console.log(user, pass)

            const res = await clientService.getUser(user, pass)
            console.log(res)
            // console.log(res.docs.map((doc) => ({ ...doc.data(), id: doc.id })));


            if (res.status >= 200 && res.status < 300) {
                localStorage.setItem('userData', JSON.stringify(res.data))
                toast.success('Logged in Successfully')
                setTimeout(()=>{
                    // window.location('/')
                    history.push('/')
                    setLoading(false)

                },1000)
                setLoading(true)
                console.log('success')
                // alert('success')

            } else {
                toast.error('Please check login credentials')
                setLoading(false)
            }



        } catch (error) {
            console.log(error)
            setLoading(false)
            toast.error('some error occured')

            // toast.error("some error occured",error)
        }
    }





    const handleEmail = (e) => {
        setUser(e.target.value)
    }
    const handlePassword = (e) => {
        setPass(e.target.value)
    }

    // console.log("email",email,"password",pass)

    return (
      <React.Fragment>
        <Helmet>
          <link rel="icon" href={favIcon} />
        </Helmet>
        <OffWrap />
        <Whatsapp/>
        {/* <HeaderStyleSix
          parentMenu="home"
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          mobileNormalLogo={mobileLogo}
          headerClass="full-width-header header-style1 home1-modifiy home12-modifiy"
          TopBar="enable"
          TopBarClass="topbar-area home11-topbar"
          phoneNumber="(+01) 999-999-4444"
          emailAddress="support@website.com"
          Location="374 William S Canning Blvd, MA 2721, USA "
        /> */}

        {/* breadcrumb-area-start */}
        {/* <SiteBreadcrumb
          pageTitle="Login"
          pageName="Login"
          breadcrumbsImg={bannerbg}
        /> */}
        {/* breadcrumb-area-End */}

        {/* Login Part Start */}
        <div className="rs-login pt-100 pb-100 md-pt-80 md-pb-80">
          <div className="container">
            <div className="noticed">
              <div className="main-part">
                <div className="method-account">
                  <h2 className="login">Login</h2>
                  <form>
                    <input
                      type="text"
                      name="username"
                      placeholder="Username"
                      required
                      onChange={handleEmail}
                    />
                    <input
                      type="password"
                      name="Password"
                      placeholder="Password"
                      required
                      onChange={handlePassword}
                    />
                    {loading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="readon submit-btn"
                        onClick={handlesubmit}
                      >
                        Login
                      </button>
                    )}
                    <div className="last-password">
                      <p>
                        Not registered?{' '}
                        <Link to="/register">Create an account</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Login Part End */}

        {/* <Newsletter
          sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
          titleClass="title mb-0 white-color"
        />

        <Footer
          footerClass="rs-footer home9-style main-home"
          footerLogo={footerLogo}
        /> */}

        {/* scrolltop-start */}
        <ScrollToTop scrollClassName="scrollup orange-color" />
        {/* scrolltop-end */}

        <SearchModal />
      </React.Fragment>
    );
}


export default Login;