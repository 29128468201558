import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import favIcon from '../../assets/img/fav.png';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Whatsapp from './Whatsapp';

export default function Contact() {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />
      <div className="row y-middle mt-50 mb-50 md-mb-30">
        <div className="col-md-6 sm-mb-30">
          <div
            className="rs-breadcrumbs breadcrumbs-overlay"
            style={{ color: 'black' }}
          >
            <div className="breadcrumbs-text black-color">
              <ul>
                <li>
                  <Link to="/dashboard" className="active">
                    Home
                  </Link>
                </li>

                <li>
                  <Link to="#" className="active">
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="rs-contact style4 pt-110 md-pt-80 pb-110 md-pb-80">
        <div className="container">
          <div class="row gutter-30">
            <div class="col-md-4 sm-mb-30">
              <div class="address-box ">
                <div class="address-icon">
                  <i class="fa fa-map-o pt-16"></i>
                </div>
                <div class="address-text">
                  <span class="label">Address</span>
                  <div class="address" style={{fontSize:'1.2rem'}}>Sky Aurora , Al Rayyan Complex , Al Nahda 065306653</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 sm-mb-30">
              <div class="address-box ">
                <div class="address-icon">
                  <i class="fa fa-envelope-open-o"></i>
                </div>
                <div class="address-text">
                  <span class="label">Email Addresss</span>
                  <a class="email" href="mailto:info@educavo.com" style={{fontSize:'1rem'}}>
                  skyauroratourismshj@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="address-box ">
                <div class="address-icon">
                  <i class="fa fa-headphones pt-16"></i>
                </div>
                <div class="address-text">
                  <span class="label">Phone Number</span>
                  <a class="phone" href="tel:999-999-4444" style={{fontSize:'1.2rem'}}>
                  +971569291125
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
