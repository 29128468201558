import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import HeaderStyleSix from '../../components/Layout/Header/HeaderStyleSix';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import AccountMain from '../../components/Shop/AccountMain';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

import { Link, useHistory } from 'react-router-dom';
// Image
import favIcon from '../../assets/img/applogo.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';
import mobileLogo from '../../assets/img/logo/logo-green.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import teamImg1 from '../../assets/img/team/9.jpg';

import thumb1 from '../../assets/img/blog/style2/1.jpg';
import clientService from '../service/clientService';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify'
import Whatsapp from '../sky-services/Whatsapp';

const MyAccount = () => {
  const history = useHistory();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
      window.location.reload();
    }
  }, []);

  const [user, setUser] = useState([]);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData?.id;
  const companyID = userData?.companyid;

  useEffect(() => {
    if (userId !== undefined && userId !== '') {
      getUserDetails();
    } else {
      history.push('/login');
      window.location.reload();
    }
    getCompanyUser();
  }, []);

  const [showCompanyIdField, setShowCompanyIdField] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [userList, setUserList] = useState([]);

  const getCompanyUser = async () => {
    try {
      // console.log(companyID)

      const res1 = await clientService.getUsersByCompanyId(companyID);
      console.log(res1.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setUserList(res1.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      // console.log(res1)
    } catch (error) {
      console.log('some error occured', error);
    }
  };



  const handleCompanyInputChange = (e) => {
    setCompanyId(e.target.value);
  };



  const handleSaveCompanyId = async () => {
    try {
      const data = await clientService.getUserCompanyId(companyId);
      console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      const res = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      if (res.length === 0) {
        toast.error('ID does not exist');
      } else {
        // Call the updateUser function from your client service
        const res1 = await clientService.updateUser(userId, {
          companyid: companyId,
          companyReqStatus:"request"
        });
        console.log(res1); // Log the response from the updateUser function
        console.log('Company ID updated');
        toast.success('Request send successfully');
        getUserDetails()


        // Reset the field and hide it
        setShowCompanyIdField(false);
      }
    } catch (error) {
      console.error('Error updating company ID:', error);
      // Handle error appropriately
    }

  };



  const getUserDetails = async () => {
    // console.log(userId);
    const res = await clientService.getloggeduser(userId);
    // console.log(res.data());
    const userDetails = res.data();

    setUser(userDetails);
  };
  console.log(user)

  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };

  const handleReqAccep = async (userid) => {
    try {
        console.log(userid)

        // Call the updateUser function from your client service
        const res1 = await clientService.updateUserReq(userid, {
            companyReqStatus: "accept"
        });
        console.log(res1); // Log the response from the updateUser function
        toast.success('User Accepted');
        // getData()
        getCompanyUser()
    } catch (error) {
        console.error('Error updating company ID:', error);
        // Handle error appropriately
    }

};

const handleReqDecline = async (userid) => {
    try {
        console.log(userid)

        // Call the updateUser function from your client service
        const res1 = await clientService.updateUserReq(userid, {
            companyid: "",
            companyReqStatus: "decline"
        });
        console.log(res1); // Log the response from the updateUser function
        console.log('Company ID updated');
        toast.success('User Declined');
        // getData()
        getCompanyUser()
    } catch (error) {
        console.error('Error updating company ID:', error);
        // Handle error appropriately
    }

};

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />

      <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link to="#" className="active">
                        Account
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="/my-account">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                          

                            <div className="cart-btn text-center">
                              {/* <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link> */}
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}
                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="profile-section pt-100 pb-90 md-pt-80 md-pb-70">
                <div className="container">
                  <div className="row clearfix">
                    <div className="image-column col-lg-5 md-mb-50">
                      <div className="inner-column mb-50 md-mb-0">
                        <div class="d-flex justify-content-center">
                          {' '}
                          <div className="image align-center">
                            <img
                              src="https://th.bing.com/th/id/OIP.lcdOc6CAIpbvYx3XHfoJ0gAAAA?rs=1&pid=ImgDetMain"
                              alt="images"
                              style={{ width: '100px' }}
                            />
                          </div>
                        </div>

                        <div className="team-content text-center">
                          <h3>{user.name}</h3>
                          {user.isCompany === false ? (
                            <div className="text">Individual</div>
                          ) : (
                            <div>
                              <div className="text">Company</div>
                            </div>
                          )}

                          <div>
                            {user.companyid !== null &&
                              user.companyId !== '' ? (
                              <p>Company Id: {user.companyid}</p>
                            ) : null}
                          </div>
                          <ul className="personal-info">
                            <li className="email">
                              <span>
                                <i className="glyph-icon flaticon-email"> </i>{' '}
                              </span>
                              <Link to="#">{user.mailId}</Link>
                            </li>
                            <li className="phone">
                              <span>
                                <i className="glyph-icon flaticon-call"></i>
                              </span>
                              <Link to="#">{user.phone}</Link>
                            </li>
                          </ul>
                          {user.isCompany === false && (
                            <div>
                              <button
                                className="btn"
                                onClick={() => setShowCompanyIdField(true)}
                              >
                                Update Company ID
                              </button>
                            </div>
                          )}

                          {showCompanyIdField && (
                            <div className="p-4">
                              <label className="p-3" htmlFor="">
                                Add Company
                              </label>

                              <input
                                type="text"
                                value={companyId}
                                onChange={handleCompanyInputChange}
                              />
                              <button onClick={handleSaveCompanyId}>
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="social-box">
                          <span style={{ font: '1px !important' }}>
                            {' '}
                            Sign Out
                          </span>
                          <Link to="#" onClick={(e) => signOut()}>
                            <i className="fa fa-sign-out"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="content-column col-lg-7 pl-60 pt-50 md-pl-14 md-pt-0">
                      {user.isCompany === true && (
                        <div className="inner-column">
                          <div className="team-skill mb-50">
                            <h3 className="skill-title">User List:</h3>
                            <div className="rs-inner-blog orange-style pt-10 pb-100 md-pt-80 md-pb-80">
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 order-last">
                                    <div className="row recent-posts-widget mb-50">
                                      <div className="col-md-6 sm-mb-20 widget-area">
                                        <h3 className="widget-title">
                                          Avalilable Users
                                        </h3>
                                        <div className="card shadow p-3 " style={{ width: '19rem', }}>
                            <div className="card-body">
                                <h5 className="card-title" style={{ textAlign: 'center' }}>Accepted User List</h5>
                                <table>

                                    <tr>
                                        <th >User List </th>
                                        <th></th>
                                    </tr>
                                    {userList?.filter(list => list?.companyReqStatus == 'accept').map((list, index) =>
                                        <tr key={index}>

                                            <td>{list.name}</td>
                                            <td> <button  onClick={() => handleReqDecline(list.id)}>Decline</button> </td>

                                        
                                    </tr>
                                    )}


                            </table>
                        </div>
                    </div>



                    <div className="card shadow p-3 mt-5 " style={{ width: '25rem', }}>
                        <div className="card-body">
                            <h5 className="card-title" style={{ textAlign: 'center' }}>Requested User List</h5>
                            <table>

                                <tr>
                                    <th >User List  </th>
                                    <th></th>
                                </tr>
                                {userList?.filter(list => list?.companyReqStatus == 'request').map((list, index) => (
                                    <tr key={index}>

                                        <td>{list.name}</td>
                                        <td>
                                            <button  onClick={() => handleReqAccep(list.id)}>Accept</button> &nbsp;
                                            <button  onClick={() => handleReqDecline(list.id)}>Decline</button> </td>


                                    </tr>
                                ))}


                            </table>
                        </div>
                    </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyAccount;
