import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
  const { parentMenu, secondParentMenu } = props;

  const location = useLocation();

  return (
    <React.Fragment>
      <li
        className={
          parentMenu === 'home'
            ? 'rs-mega-menu menu-item-has-children current-menu-item'
            : 'rs-mega-menu menu-item-has-children'
        }
      >
        <Link to="/">Home</Link>
      </li>
      <li
        className={
          parentMenu === 'about'
            ? 'menu-item-has-children current-menu-item'
            : 'menu-item-has-children'
        }
      >
        <Link to="">Services</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/visa/family/request"
              className={
                location.pathname === '/visa/family/request'
                  ? 'active-menu'
                  : ''
              }
            >
              Family Visa
            </Link>
          </li>
          <li>
            <Link
              to="/certificate/request"
              className={
                location.pathname === '/certificate/request'
                  ? 'active-menu'
                  : ''
              }
            >
              Attestation
            </Link>
          </li>
          <li>
            <Link
              to="/schengen/request"
              className={
                location.pathname === '/schengen/request' ? 'active-menu' : ''
              }
            >
              Schengen Visa
            </Link>
          </li>
          <li>
            <Link
              to="/docremainder/request"
              className={
                location.pathname === '/docremainder/request' ? 'active-menu' : ''
              }
            >
              Reminder
            </Link>
          </li>
        </ul>
      </li>
     
      <li
        className={
          parentMenu === 'pages'
            ? 'menu-item-has-children current-menu-item'
            : 'menu-item-has-children'
        }
      >
        <Link to="/register">Sign Up</Link>
      </li>

      <li
        className={
          parentMenu === 'pages'
            ? 'menu-item-has-children current-menu-item'
            : 'menu-item-has-children'
        }
      >
        <Link to="/view">ViewList</Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
