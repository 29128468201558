import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Reminders from './Reminders';
import FamilyVisa from './FamilyVisa';
import Certifcate from './Certifcate';
import Shengen from './Shengen';

const DetailsTab = ({comid}) => {
  let tab1 = 'Reminders',
    tab2 = 'Family Visa',
    tab3 = 'Certificate',
    tab4 = 'Shengen';

  const tabStyle = 'intro-tabs tabs-box';
  console.log(comid)

  return (
    <div className="intro-info-tabs">
      <Tabs>
        <TabList className={tabStyle}>
          <Tab>
            <button>{tab1}</button>
          </Tab>
          <Tab>
            <button>{tab2}</button>
          </Tab>
          <Tab>
            <button>{tab3}</button>
          </Tab>
          <Tab>
            <button>{tab4}</button>
          </Tab>
        </TabList>

        <TabPanel>
          <Reminders  comid={comid} />
        </TabPanel>

        <TabPanel>
          <FamilyVisa comid={comid} />
        </TabPanel>

        <TabPanel>
          <Certifcate  comid={comid}/>
        </TabPanel>

        <TabPanel>
          <Shengen comid={comid} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default DetailsTab;
