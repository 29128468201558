import React, { useEffect, useState } from 'react';


// Image
import favIcon from '../../../assets/img/applogo.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

import Header from '../../../components/Layout/Header';
import OffWrap from '../../../components/Layout/Header/OffWrap';

import bannerbg from '../../../assets/img/breadcrumbs/6.jpg';
import Footer from '../../../components/Layout/Footer/Footer';
import { Helmet } from 'react-helmet';
import DetailsTab from './DetailsTab';
import clientService from '../../service/clientService';
import { useParams } from 'react-router-dom';





export default function View() {



  useEffect(() => {
    getSchengenVisa();
    getAllFamilyVisa()
    getAllDocRem()
    getAllcertiAttestation()
  }, []);


  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData.id;
  const [schengenList, setSchengenList] = useState([]);
  const [family, setFamily] = useState([])
  const [remList, setRemList] = useState([]);
  const [attestList, setAttestList] = useState([]);

  const {id}=useParams()
  console.log(id)



  const getSchengenVisa = async () => {
    const data = await clientService.getschengenVisasAll(id);
    setSchengenList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const getAllFamilyVisa = async () => {
    const data = await clientService.getAllFamily(id);
    setFamily(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };


  const getAllDocRem = async () => {
    try {
      // Call the function with userId
      console.log(userId);
      const data = await clientService.getsingleDocRem(id);
      setRemList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAllcertiAttestation = async () => {
    try {
      const data = await clientService.getAllcertificateAttestation(id);
      setAttestList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) { }
  };





  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="shop"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />
      <SiteBreadcrumb
        pageTitle="Activities"
        pageName="Activities"
        breadcrumbsImg={bannerbg}
      />

      <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-8 md-mb-50">
              <DetailsTab comid={id} />
            </div>
            <div className="video-column col-lg-4">
              <div className="inner-column">
                <div className="course-features-info">
                  <ul>
                    <li className="lectures-feature">
                      <i className="fa fa-files-o"></i>
                      <span className="label">Reminders</span>
                      <span className="value">{remList?.length}</span>
                    </li>

                    <li className="quizzes-feature">
                      <i className="fa fa-puzzle-piece"></i>
                      <span className="label">Family Visa</span>
                      <span className="value">{family?.length}</span>
                    </li>

                    <li className="duration-feature">
                      <i className="fa fa-clock-o"></i>
                      <span className="label">Attestation</span>
                      <span className="value">{attestList?.length}</span>
                    </li>

                    <li className="students-feature">
                      <i className="fa fa-users"></i>
                      <span className="label">Shengen Visa</span>
                      <span className="value">{schengenList?.length}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        footerClass="rs-footer home9-style home12-style"
        footerLogo={Logo}
      />
    </React.Fragment>
  );
}
