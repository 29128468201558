import React from 'react'
import styled from 'styled-components';




const WhatsAppButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25D366; /* WhatsApp green */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-decoration: none;

  .fa-whatsapp {
    color: white;
    font-size: 2rem; /* Adjust the size of the icon */
  }

  &:hover {
    background-color: #128C7E; /* Darker green on hover */
  }
`;


const Whatsapp = () => {
  return (
    <div>
      <WhatsAppButton href="https://wa.me/971569291125" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-whatsapp"></i>
      </WhatsAppButton>


    </div>
  )
}

export default Whatsapp
