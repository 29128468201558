import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Image
import favIcon from '../../../assets/img/applogo.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

import Header from '../../../components/Layout/Header';
import OffWrap from '../../../components/Layout/Header/OffWrap';

import bannerbg from '../../../assets/img/breadcrumbs/6.jpg';
import Footer from '../../../components/Layout/Footer/Footer';
import Whatsapp from '../Whatsapp';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import CountryList from './CountryList';

import { Form, Button, FormSelect } from 'react-bootstrap'
import clientService from '../../service/clientService'
import { Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { isStyledComponent } from 'styled-components';



export default function Index() {

  const history = useHistory()
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);

  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };


  const [nation, setNation] = useState([])
  const [nationselect, setNationSelect] = useState('')
  const [visa, setVisa] = useState([])
  const [visaselect, setVisaSelect] = useState('')
  const [country, setCountry] = useState([])
  const [countryselect, setCountrySelect] = useState('')
  const [visastatus, setVisaStatus] = useState([])
  const [visastatsel, setVisaStatSel] = useState('')
  const [number, setNumber] = useState('')
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  console.log(nation)

  const userData = JSON.parse(localStorage.getItem('userData'))
  // console.log(userData)
  const userId = userData.id
  const username = userData.name
  // console.log(userId, username)

  useEffect(() => {
    const handlePopState = (event) => {
      const userConfirmed = window.confirm("Are you sure you want to go back?");
      if (!userConfirmed) {
        history.go(1); // Move forward in history stack to cancel back navigation
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  useEffect(() => {
    getNation()
    getVisa()
    getAllCountry()
    getVisaStatus()
  }, [])

  useEffect(() => {
    if (id !== undefined && id !== '') {
      editSchengen()
    }

  }, [])

  console.log(number)

  const editSchengen = async () => {
    try {
      const docSnap = await clientService.getschengenOne(id);
      console.log(docSnap.data())
      const data = docSnap.data();

      setNationSelect(data.nationality.id);
      console.log(data.nationality.id);
      setVisaSelect(data.visaPurpose.id);
      console.log(data.visaPurpose.id);
      setCountrySelect(data.country.id);
      console.log(data.country.id);
      setVisaStatSel(data.visaStatus.id);
      setNumber(data.contactNumber);
    } catch (error) {
      console.log(error);
    }
  };





  const getNation = async () => {

    const response = await clientService.getNationalityAll()
    // console.log( data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    // setNation(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    const dataString = response.docs; // Adjust this if necessary based on actual API response structure

    const sortedData = dataString
      .map((doc) => ({ ...doc.data(), id: doc.id })) // Assuming each doc has an 'id' and other properties
      .sort((a, b) => (a.name || '').localeCompare(b.name || '')); // Sort alphabetically by 'name'

    setNation(sortedData);

  }
  // console.log(nation)



  const getVisa = async () => {

    const data = await clientService.getAllVisa()
    // console.log( data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    setVisa(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

  }
  // console.log(visa)

  const getAllCountry = async () => {
    const data = await clientService.getShenCountry()
    setCountry(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  }
  console.log(country)


  const getVisaStatus = async () => {
    const data = await clientService.getVisaStatus()
    setVisaStatus(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  }
  // console.log(visastatus)

  const handleNatSelect = async (e) => {

    setNationSelect(e.target.value)

  }
  console.log(nationselect)

  // console.log(nationselect)

  const handleVisaSelect = async (e) => {

    setVisaSelect(e.target.value)
  }
  // console.log(visaselect)

  const handleCountrySelect = async (e) => {

    setCountrySelect(e.target.value)
  }
  // console.log(countryselect)

  const handleVisaStatus = async (e) => {

    setVisaStatSel(e.target.value)
  }
  // console.log(visastatsel)


  // console.log(number)




  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const currentDate = Timestamp.now();

    let data = {
      nationality: {
        name: nation.find(nation => nation.id === nationselect)?.name,
        id: nationselect
      },
      country: {
        name: country.find(ctry => ctry.value === countryselect)?.label,
        id: countryselect
      },
      visaPurpose: {
        name: visa.find(visa => visa.id === visaselect)?.name,
        id: visaselect
      },
      visaStatus: {
        name: visastatus.find(visa => visa.id === visastatsel)?.name,
        id: visastatsel
      },
      contactNumber: number,
      createdDate: currentDate,
      updatedDate: currentDate,

      requestedStatus: 'requested',
      user: {
        name: username,
        userId: userId
      },

    };
    console.log("create schengen", data)

    try {
      if (id !== undefined && id !== '') {
        const res = await clientService.updateSchenegen(id, data);
        // const res = await clientService.addSchengenVisa(data)
        console.log("data added successfully", res)
        toast.success('Schengen Visa  added successfully')
        console.log("docrem data added successfully", res);
        setTimeout(() => {
          // window.location('/')
          history.push('/dashschengenview')
          setLoading(false)

        }, 1000)
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
      toast.error('Error: Please try again later.');
      setLoading(false)

    }
  }


  const handleNumber = async (e) => {
    e.preventDefault()
    setNumber(e.target.value)

    const phoneNumberPattern = /^\d{9}$/;

    if (!phoneNumberPattern.test(e.target.value)) {
      setError('Please enter a valid 9-digit phone number.');
    } else {
      setError('');
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />

      <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link to="/dashschengenview" className="active">
                        Schengen Visa
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="active">
                        Update
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="#">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                            <ul className="cart-icon-product-list">
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-info-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/shop/cart">About Us</Link>
                                  <br />
                                  {/* <span className="quantity">1 × $30.00</span> */}
                                </div>
                                <div className="product-image"></div>
                              </li>
                            </ul>

                            <div className="cart-btn text-center">
                              <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link>
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}
                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="course-overview">
                <div className="inner-box">
                  <div
                    id="rs-blog"
                    className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            id="rs-about"
                            className="rs-about style1 pb-100 md-pb-70"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-11 order-last">
                                  <div className="notice-bord style1">
                                    <div className="rs-checkout orange-style pt-100 pb-100 md-pt-70 md-pb-70">
                                      <div className="container">

                                        <div className="full-grid">
                                          <form id="checkout-form" >
                                            <div className="billing-fields">
                                              <div className="checkout-title">
                                                <h3>Basic details</h3>
                                              </div>
                                            </div>
                                            <div className="form-content-box">
                                              <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Nationality *</label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          value={nationselect}
                                                          onChange={handleNatSelect}
                                                          className='form-control'
                                                          required

                                                        >
                                                          <option value="">Select Nationality</option>

                                                          {nation?.map((nat, index) => (
                                                            <option key={index} value={nat.id}>{nat.name}</option>
                                                          ))}

                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Visa Purpose*</label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          className='form-control'
                                                          required
                                                          value={visaselect}
                                                          onChange={handleVisaSelect}
                                                        >
                                                          <option value="">Select Visa Purpose</option>

                                                          {visa?.map((visa, index) => (
                                                            <option key={index} value={visa.id}>{visa.name}</option>

                                                          ))}

                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Country *</label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect
                                                          value={countryselect}
                                                          className='form-control'
                                                          required
                                                          onChange={handleCountrySelect}
                                                        >
                                                          <option value="">Select Country</option>
                                                          {country?.map((cntry, index) => (
                                                            <option key={index} value={cntry.value}>{cntry.label}</option>
                                                          ))}
                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Visa Status *</label>
                                                        {/* <CountryList /> */}
                                                        <FormSelect value={visastatsel} onChange={handleVisaStatus} className='form-control'
                                                          required>
                                                          <option value="">Select Visa Status</option>

                                                          {visastatus?.map((visa, index) => (
                                                            <option key={index} value={visa.id}>{visa.name}</option>

                                                          ))}
                                                        </FormSelect>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="d-flex form-group col-lg-12 mb-25">
                                                  <div className="form-control" style={{ width: '70px', maxWidth: '70px', paddingRight: '0', paddingLeft: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                    <span className="text-center">+971</span>
                                                  </div>
                                                  <input
                                                    name="hnumber"
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Enter number here"
                                                    required
                                                    value={number}
                                                    style={{ flex: '1' }}
                                                    onChange={handleNumber}
                                                  />
                                                  {error && <div style={{ color: 'red' }}>{error}</div>}
                                                </div>
                                              </div>
                                            </div>


                                            <div className="payment-method mt-40 md-mt-20">
                                              <div className="top-area">
                                                <div className="p-msg">
                                                  {' '}
                                                  Your personal data will be used to process your order,
                                                  support your experience throughout this website, and for
                                                  other purposes described in our privacy policy.
                                                </div>
                                              </div>
                                              {loading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                                </div>
                                              ) : (
                                                <div className="bottom-area">
                                                  <p className="mt-14"></p>
                                                  <button className="btn-shop orange-color" onClick={handleSubmit} type="submit">
                                                    Update
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </form>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
