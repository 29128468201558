import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../../components/Layout/Header/OffWrap';

import favIcon from '../../../assets/img/fav.png';
import { Link, useHistory } from 'react-router-dom';


import clientService from '../../../pages/service/clientService';

import NoImage from '../../../assets/img/no-image.png';

import './view.css';
import Whatsapp from '../Whatsapp';

export default function Reminders() {
  const [remList, setRemList] = useState([]);
  const history = useHistory()

  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData?.id;

  useEffect(() => {
    const handlePopState = (event) => {
      const userConfirmed = window.confirm("Are you sure you want to go back?");
      if (!userConfirmed) {
        history.go(1); // Move forward in history stack to cancel back navigation
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  useEffect(() => {
    getAllDocRem();
  }, []);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);

  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };



  const getAllDocRem = async () => {
    try {
      console.log(userId);
      const data = await clientService.getsingleDocRem(userId);
      setRemList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  console.log(remList);

  const calculateDaysRemaining = (expiryDate) => {
    const expiryTime = expiryDate.getTime();
    const currentTime = new Date().getTime();
    const timeDiff = expiryTime - currentTime;
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining;
  };

  const deleteRem = async (id) => {
    console.log(id);
    console.log(`deleted id ${id}`);
    const res = await clientService.deleteRemainder(id);
    getAllDocRem();
  };

  const handleRenewal = async (id) => {
    try {
      // Call your client service function to update the status
      if (
        window.confirm(
          'Do you want to Request for renewal ?'
        )
      ) {

        const res = await clientService.updateDocRemStatus(id, {
          requestedStatus: 'requestedRenewal',
        });
        getAllDocRem()
      }


      // Logging the response for debugging
    } catch (error) {
      console.error('Error occurred while updating status:', error);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />

      <Whatsapp />
      <div className="rs-categories gray-bg style1 pt-40 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-30 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                       <b>Home</b> 
                      </Link>
                    </li>

                    <li>
                      <Link to="#" className="active">
                        <b>Document Reminder</b>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="/my-account">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                            {/* <ul className="cart-icon-product-list">
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-info-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/about">About Us</Link>
                                </div>
                              </li>
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-user-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/my-account">Profile</Link>
                                </div>
                              </li>
                            </ul> */}

                            <div className="cart-btn text-center">
                              <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link>
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}
                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="course-overview">
                <div className="inner-box">
                  <div
                    id="rs-blog"
                    className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
                  >
                    <div class="d-flex flex-row-reverse bd-highlight">
                      <div class="p-2 bd-highlight">
                        {' '}

                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            id="rs-about"
                            className="rs-about style1 pb-100 md-pb-70"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12 order-last">
                                  <div className="notice-bord style1">
                                    <div className="d-flex align-items-center my-3" style={{ width: '100%' }}>
                                      <h4 className="title mb-0" style={{ flexBasis: '80%', flexGrow: 1, marginRight: 0 }}>Document Reminder</h4>
                                      <Link to={'/docremainder/request'} style={{ flexBasis: '20%', flexGrow: 0 }}>
                                        <button className="btn btn-custom" style={{ width: '100%' }}>Create</button>
                                      </Link>
                                    </div>


                                    <ul>

                                      {
                                        remList?.length === 0 ? (
                                          <>
                                           <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                              <img src={NoImage} style={{ width: '25%', marginRight: '10px' }} alt="No data available" />
                                            </span>
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data available</span>
                                          </>
                                        ) :
                                          remList?.map((item, index) => {
                                            const expiryDate = new Date(
                                              item.ExpairyDate.seconds * 1000
                                            );
                                            console.log(item.ExpairyDate);
                                            const daysRemaining =
                                              calculateDaysRemaining(
                                                expiryDate
                                              );
                                            return (
                                              <li>
                                                <div class="d-flex bd-highlight">
                                                  <div class="p-2 flex-grow-1 bd-highlight">
                                                    <div className="date">
                                                      <div
                                                        className={`date-part ${daysRemaining >= 10
                                                          ? 'bgc4'
                                                          : ' bgc2 '
                                                          }`}
                                                      >
                                                        <div className="date">
                                                          {daysRemaining} Days
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="desc ml-3">
                                                      {item.DocType.name}{' '}
                                                      Expired On{' '}
                                                      {new Date(
                                                        item.ExpairyDate
                                                          .seconds * 1000
                                                      ).toLocaleDateString()}
                                                      <br />
                                                      {/* <p> */}
                                                        Nationality:{' '}
                                                        {item.nationality.name}
                                                      {/* </p> */}
                                                      <br/>
                                                      Status:{' '}
                                                      {item?.requestedStatus}
 
                                                   </div>
                                                  </div>

                                                  <div class="p-2 bd-highlight">
                                                    <div className="desc ml-3">
                                                      <div class="d-flex bd-highlight mb-3">
                                                        <div class="p-2 bd-highlight">
                                                          <Link
                                                            to={`/doc/update/${item.id}`}
                                                          >
                                                            <i
                                                              className="fa fa-edit"
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '25px',
                                                              }}
                                                            ></i>
                                                          </Link>
                                                        </div>
                                                        <div class="p-2 bd-highlight">
                                                          <i
                                                            onClick={() => {
                                                              if (
                                                                window.confirm(
                                                                  'Do you want to delete ?'
                                                                )
                                                              ) {
                                                                deleteRem(
                                                                  item.id
                                                                );
                                                              }
                                                            }}
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: '25px',
                                                              color: '#bd4141',
                                                            }}
                                                          ></i>
                                                        </div>
                                                        {daysRemaining <= 30 ? (
                                                          item.requestedStatus !==
                                                            'requestedRenewal' &&
                                                            item.requestedStatus !==
                                                            'underProcess' ? (
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                  'flex-end',
                                                              }}
                                                            >
                                                              <div
                                                                class="btn-custom"
                                                                onClick={() =>
                                                                  handleRenewal(
                                                                    item.id
                                                                  )
                                                                }
                                                                style={{
                                                                  fontSize:
                                                                    '10px',
                                                                  color:
                                                                    'white',
                                                                }}
                                                              >
                                                                Request For
                                                                Renewal
                                                              </div>
                                                            </div>
                                                          ) : item.requestedStatus ===
                                                            'requestedRenewal' ? (
                                                            <div
                                                              style={{
                                                                width: '180px',
                                                                backgroundColor:
                                                                  'white',
                                                                borderRadius:
                                                                  '8px',
                                                                padding:
                                                                  '4px 8px',
                                                                display: 'flex',
                                                                justifyContent:
                                                                  'space-evenly',
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  fontSize:
                                                                    '15px',
                                                                  color:
                                                                    'green',
                                                                }}
                                                                className="fa fa-check"
                                                              ></i>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    '12px',
                                                                }}
                                                              >
                                                                Requested for
                                                                Renewal
                                                              </span>
                                                            </div>
                                                          ) : (
                                                            <div
                                                              style={{
                                                                width: '180px',
                                                                backgroundColor:
                                                                  'white',
                                                                borderRadius:
                                                                  '8px',
                                                                padding:
                                                                  '4px 8px',
                                                                display: 'flex',
                                                                justifyContent:
                                                                  'space-evenly',
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    '12px',
                                                                  color: 'red',
                                                                }}
                                                              >
                                                                Under
                                                                Processing...
                                                              </span>
                                                            </div>
                                                          )
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );

                                          })

                                      }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
